var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("user-info", { attrs: { data: _vm.userData } }),
      _c("div", { staticClass: "form-block" }, [
        _c(
          "div",
          { staticClass: "source" },
          [
            _c(
              "el-form",
              {
                ref: "formData",
                staticClass: "demo-form",
                attrs: {
                  model: _vm.formData,
                  "status-icon": "",
                  rules: _vm.rules,
                  "label-position": "left"
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "Enabled", prop: "enabled" } },
                  [
                    _c("el-switch", {
                      model: {
                        value: _vm.formData.enabled,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "enabled", $$v)
                        },
                        expression: "formData.enabled"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "Name", prop: "name" } },
                  [
                    _c("el-input", {
                      attrs: {
                        name: "name",
                        required: "",
                        placeholder: "Name"
                      },
                      model: {
                        value: _vm.formData.name,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "name", $$v)
                        },
                        expression: "formData.name"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "Line 1", prop: "line1" } },
                  [
                    _c("el-input", {
                      attrs: {
                        name: "line1",
                        required: "",
                        placeholder: "Enter address"
                      },
                      model: {
                        value: _vm.formData.line1,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "line1", $$v)
                        },
                        expression: "formData.line1"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "Line 2", prop: "line2" } },
                  [
                    _c("el-input", {
                      attrs: {
                        name: "line2",
                        required: "",
                        placeholder: "Enter address"
                      },
                      model: {
                        value: _vm.formData.line2,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "line2", $$v)
                        },
                        expression: "formData.line2"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "GST", prop: "gstNumber" } },
                  [
                    _c("el-input", {
                      attrs: {
                        name: "gstNumber",
                        required: "",
                        placeholder: "Enter GST"
                      },
                      model: {
                        value: _vm.formData.gstNumber,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "gstNumber", $$v)
                        },
                        expression: "formData.gstNumber"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "PAN", prop: "panNumber" } },
                  [
                    _c("el-input", {
                      attrs: {
                        name: "panNumber",
                        required: "",
                        placeholder: "Enter PAN"
                      },
                      model: {
                        value: _vm.formData.panNumber,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "panNumber", $$v)
                        },
                        expression: "formData.panNumber"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "FSSAI", prop: "fssaiNumber" } },
                  [
                    _c("el-input", {
                      attrs: {
                        name: "fssaiNumber",
                        required: "",
                        placeholder: "Enter FSSAI"
                      },
                      model: {
                        value: _vm.formData.fssaiNumber,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "fssaiNumber", $$v)
                        },
                        expression: "formData.fssaiNumber"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: {
                      label: "Enter Pincode",
                      prop: "pincodeId",
                      required: ""
                    }
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          filterable: "",
                          remote: "",
                          "remote-method": _vm.getPincodeList,
                          name: "Pincode",
                          placeholder: "Enter Pincode Name"
                        },
                        model: {
                          value: _vm.formData.pincodeId,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "pincodeId", $$v)
                          },
                          expression: "formData.pincodeId"
                        }
                      },
                      _vm._l(_vm.pincodeList, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.pincode, value: item.id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading"
                          },
                          { name: "waves", rawName: "v-waves" }
                        ],
                        attrs: { type: "success" },
                        on: { click: _vm.submitForm }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [{ name: "waves", rawName: "v-waves" }],
                        attrs: { type: "reset" },
                        on: { click: _vm.resetForm }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }